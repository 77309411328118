export enum RouteParamName {
  id = 'id',
  workspaceId = 'workspaceId',
}

export enum QueryParamName {
  resend = 'resend',
  code = 'code',
  token = 'token'
}
